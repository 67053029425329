import React, { createRef, Component, RefObject } from "react";
import WindowAnimation from "../frontend/WindowAnimation";

interface Props {
  animate: boolean;
}

interface State {
  className: string;
}

export default class Header extends Component<Props, State> {
  declare readonly ref: RefObject<HTMLElement>;
  animationHandle: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      className: ""
    };

    this.ref = createRef();
  }

  componentDidMount() {
    if (this.props.animate) {
      this.startAnimation();
    }
  }

  componentWillUnmount() {
    if (this.props.animate) {
      WindowAnimation.clear(this.animationHandle);
    }
  }

  startAnimation() {
    const elem = this.ref.current;
    const container = elem.parentNode.parentNode as HTMLElement;
    let headerHeight = elem.offsetHeight;
    let top = WindowAnimation.elementPosition(container)[1];
    let bottom = top + container.offsetHeight - headerHeight;

    this.animationHandle = WindowAnimation.register({
      resize: function () {
        headerHeight = elem.offsetHeight;
        top = WindowAnimation.elementPosition(container)[1];
        bottom = top + container.offsetHeight - headerHeight;
      },
      scroll: (pos) => {
        if (pos >= bottom) {
          this.setState({ className: "bottom" });
        } else if (pos >= top) {
          this.setState({ className: "fixed" });
        } else {
          this.setState({ className: "" });
        }
      }
    });
  }

  render() {
    return (
      <header className={this.state.className} ref={this.ref}>
        <div className="inner">
          <h1 className="logo">
            <a href="/">Anyone</a>
          </h1>
          <div className="contact">
            <h2>Kontakt</h2>
            <p>
              <a href="mailto:hello@anyone.no">hello@anyone.no</a>
              <br />
              <a href="http://facebook.com/anyone-oslo">Facebook</a>
              <br />
              <a href="http://instagram.com/anyoneoslo">Instagram</a>
            </p>
            <p>
              Anyone AS
              <br />
              c/o Factory Tøyen
              <br />
              Kjølberggata 21, 0653 Oslo
            </p>
          </div>
        </div>
      </header>
    );
  }
}
